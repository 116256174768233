<script setup lang="ts">
import { ref, computed } from 'vue'

import { getGlobalTranslation } from '~/js/composables/translations'
import { getClasses } from '~/js/composables/classes'

interface Props {
    status: string,
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {utilities: ''})

const style = ref({
    limited: {
        text: 'text-purple-600',
        background: 'bg-yellow-600',
    },
    expiry: {
        text: 'text-yellow-600',
        background: 'bg-purple-600',
    },
    match: {
        text: 'text-badge-text',
        background: 'bg-badge-pink',
    },
    g4j: {
        text: 'text-badge-text',
        background: 'bg-blue-500',
    },
})
const content = ref({
    limited: getGlobalTranslation('Populair'),
    expiry: getGlobalTranslation('Laatste kans'),
    match: getGlobalTranslation('Perfecte match'),
    g4j: getGlobalTranslation('Word go4jobber'),
})
const classes = ref({
    _default: '',
    font: 'font-primary text-xs md:text-sm font-bold uppercase',
    radius: 'rounded-full',
    spacing: 'px-4 pt-2 pb-1',
    width: 'w-fit',
})

const theme = computed(() => {
    return style.value[props.status] ?? style.value['limited']
})

const text = computed(() => {
    return content.value[props.status] ?? content.value['limited']
})

</script>
<template>
    <span 
        :class="[
            getClasses(classes),
            theme.text,
            theme.background,
            utilities, 
        ]"
    >
        {{ text }}
    </span>
</template>