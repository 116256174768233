import { Job } from '~/js/interface/jobs'
import { useJobsStore } from '~/js/stores/jobs'

export const getDistanceInKilometers = (lat1:number, lon1:number, lat2:number, lon2:number): number => {
    if(lat1 === lat2 && lon1 === lon2) return 0;
    const 
        radlat1 = Math.PI * lat1/180,
        radlat2 = Math.PI * lat2/180,
        theta = lon1 - lon2;
    const radtheta = Math.PI * theta/180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) dist = 1;
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist;
}

export const getStatus = (job:Job, sectors:Array<String>, coords:any): String|null => {
    let state: String|null = null

    const today: any = new Date()
    const todayUnix: Number =  Math.floor(today / 1000)
    const nextWeekUnix: Number = Math.floor(new Date(today.getFullYear(), today.getMonth(), today.getDate()+7) / 1000)

    const startDate = new Date(job.start_date * 1000)
    const startDateAddOneMonth: Number = Math.floor(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()+30) / 1000)


    // check if a lot of people already applied for the job within the month
    if (startDateAddOneMonth > todayUnix && job.application_count > 10) {
        state = 'limited'
    }

    // check expiry
    if (job.end_date > todayUnix && job.end_date < nextWeekUnix) {
        state = 'expiry'
    }

    // if sector matches and it's within the giving kilometer radius -> perfect match
    if (coords?.latitude && coords?.longitude) {
        const matchingSector = sectors.includes(parseInt(job.sector))
        const distance = getDistanceInKilometers(job.coordinates[0], job.coordinates[1], coords.latitude, coords.longitude)
        if (matchingSector && (distance < 30)) {
            state = 'match'
        }
    }

    if (job?.office_slug == 'go4jobs-holding') {
        state = 'g4j'
    }

    return state
}
